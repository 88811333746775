import React from 'react'
import { graphql, Link } from 'gatsby'
import arrowLeft from "../../images/long-arrow-alt-right-solid.svg"

function CallToAction(props) {
    const data = props;

    return (
        <div className="m-auto text-center text-white py-12" style={{ backgroundColor: "#DB3914" }}>
            <div className="container m-auto">
                <div className="w-full flex flex-wrap items-center">
                    <div className="w-full lg:w-3/4 flex flex-wrap justify-center">
                        <h2
                            style={{
                                color: "#fff",
                            }}
                            className="flex items-center text-3xl lg:text-4xl px-4"
                        >
                            {data.cta.ctaText}
                        </h2>
                    </div>
                    <div className="w-full lg:w-1/4 pt-4 lg:pt-0 m-auto">
                        <Link to="/epikoinonia/" className="h-appointment-btn-trans flex justify-between items-center m-auto">
                            {data.cta.ctaButtonGroup.text}
                            <img src={arrowLeft} width={14} className="ml-2" />
                        </Link>
                    </div>
                </div>

            </div>
        </div >
    );
}

export default CallToAction;

export const query = graphql`fragment HomeCtaFragment on WPGraphQL_Page_Homepageacf {
  ctaGroup {
      ctaText
      ctaButtonGroup {
          text
          url
      }
  }
}
`