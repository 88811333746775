import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

import Introduction from "../components/Global/Introduction"

import Main from "../components/About/Main"

import CallToAction from "../components/About/CallToAction"

import "../components/About/AboutUsTemplate.css";




function AboutUsTemplate(props) {
    console.log(props)
    let data = props.data.wpgraphql;

    const [activeOverview, setActiveOverview] = useState(0);


    function constructMetaData(page, currentPage) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://kleidaras-varvaridis.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    console.log(data)
    return (
        <Layout 
            header={data.menu} 
            footer={data.footer} 
            metaData={constructMetaData(data.page, props.pageContext.currentPage)} 
            services={data.services} 
            productCategories={data.productCategories}     
        >
            <div>
                <section>
                    <Introduction backImg={data.page.aboutPageAcf.introductionBackgroundImage} title={data.page.aboutPageAcf.introductionHeader} />
                </section>
                <section className="container m-auto px-4 mt-24">
                    <Main data={data.page.aboutPageAcf.aboutUsGroup} />
                </section>
                <section>
                    <CallToAction cta={data.page.aboutPageAcf.ctaGroup} />
                </section>
            </div>
        </Layout>
    );
}

export default AboutUsTemplate

export const pageQuery = graphql`query GET_ABOUT_US($id: ID!) {
  wpgraphql {
    menu: template(id:"cG9zdDozMTIz", idType:ID){
        menuAcf{
          menu{
            menuGroup{
              url
              label
              isnested
              childnodes{
                url
                label
              }
            }
          }
        }  
    }

    page(id: $id) {
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
            sourceUrl
            }
        }
        breadcrumbAcf {
            breadcrumbName
        }
        content
        aboutPageAcf {
            introductionHeader
            introductionBackgroundImage {
                sourceUrl
                altText
                imageFile {
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                    }
                }
            }

            aboutUsGroup {
                title
                subtitle
                content
                ctaContent
                image {
                    altText
                    sourceUrl
                    imageFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: CONSTRAINED, width:570, placeholder: BLURRED)
                        }
                    }
                }
            }
            ctaGroup {
                ctaText
                ctaButtonGroup {
                    text
                    url
                }
            }
        }
    }

    services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
        ...ServicesFragment
    }

    productCategories{
        edges{
            node{
                name
                slug
                categoryPageH1{
                    categoryImage{
                        altText
                        sourceUrl
                        imageFile {
                            childImageSharp {
                              gatsbyImageData(quality: 100, layout: CONSTRAINED, width:400, placeholder: BLURRED)
                            }
                        }
                    }
                }
            }
        }
    }

    footer: template(id: "cG9zdDoyMjE5") {
      id
      title
      footerInformationTemplate {
        footerContent
      address {
        addressGoogleLink
        addressLabel
      }
      hoursLabel
      hoursTitle
      informationTitle
      supportEmail
      telephoneNumber
      servicesTitle
      generalPagesTitle
    }
    }
  }
}
`
