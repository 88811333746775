import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import phoneIcon from "../../images/phone-alt-solid.svg"


function Main(props) {
    const data = props;
    console.log(data)
    return (
        <div className="m-auto container flex flex-wrap pb-20">
            <div className="w-full md:w-1/2 flex flex-col justify-center px-4 md:px-0 pr-0 md:pr-8 lg:pr-12">
                {data.data.title && (
                    <div>
                        <p className="text-xl" style={{ color: "#DB3914" }} >
                            {data.data.subtitle}
                        </p>
                        <h2 className="text-4xl font-bold" >
                            {data.data.title}
                        </h2>
                    </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: data.data.content }} className="g-editor" />
                <div className="mt-4" style={{display: "inherit"}}>
                    <div className="flex items-center rounded-full py-4 px-6" style={{backgroundColor: "#f8f8f8"}}>
                        <div
                            style={{
                                marginRight: "20px",
                                textAlign: "center",
                                height: "60px",
                                width: "60px",
                                lineHeight: "60px",
                                backgroundColor: "#DB3914",
                                borderRadius: "50%",
                            }}
                            className="flex justify-center items-center"
                        >
                            <img src={phoneIcon} width={26} />
                        </div>
                        <div className="rounded-cta-editor" dangerouslySetInnerHTML={{ __html: data.data.ctaContent }} />
                    </div>
                </div>
            </div>
            <div className="w-full md:w-1/2 flex pt-8 md:pt-0">
                <div className="m-auto">
                    <GatsbyImage
                        alt={data.data.image.altText}
                        loading={"lazy"}
                        image={data.data.image.imageFile.childImageSharp.gatsbyImageData}
                        formats={["auto", "webp", "avif"]}
                    // imgClassName={"cat-img"}
                    // className="flex justify-center"
                    />
                </div>
            </div>
        </div >
    );
}

export default Main;